nav {
    z-index: 10000;
}

.user-info-tab {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info-tab>* {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}