.hover-box {
    position: relative;
    display: inline-block;
  }
  
  .hover-box::after {
    content: attr(data-hover-text);
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    background-color: transparent;
    color: black;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    margin-left: 0px;
  }
  
  .hover-box:hover::after {
    opacity: 1;
    visibility: visible;
  }